<template>
  <nav id="sidebar" class="sidebar-view show uk-animation-slide-left-small">
    <div class="row relative">
      <div class="col-md-12">
        <a href="#" class="logo text-center" name="logo">
          <img src="/assets/img/logoo.png" class="w-100" />
        </a>
      </div>
    </div>
    <div class="menu-items-wrapper">
      <ul class="list-unstyled components mb-5">
        <router-link :to="list.path" tag="li" v-for="(list, index) in lists" :key="'A' + index">
          <a><img :src="'/assets/img/' + list.icon" class="mr-3"/> <span class="menu-name">{{ list.name }}</span> </a>
        </router-link>
        <h6 class="side-title">CAR RENTAL</h6>
        <hr class="mt-2 mb-2" style="border-top: 1px solid rgb(45 63 82) !important;">
        <router-link :to="list.path" tag="li" v-for="(list, index) in second_list" :key="'B'  + index">
          <a><img :src="'/assets/img/' + list.icon" class="mr-3"/> <span class="menu-name">{{ list.name }}</span> </a>
        </router-link>
        <h6 class="side-title">FLEET</h6>
        <hr class="mt-2 mb-2" style="border-top: 1px solid rgb(45 63 82) !important;">
        <router-link :to="list.path" tag="li" v-for="(list, index) in third_list" :key="'C' + index">
          <a><img :src="'/assets/img/' + list.icon" class="mr-3"/> <span class="menu-name">{{ list.name }}</span> </a>
        </router-link>
        <h6 class="side-title">CONTACTS</h6>
        <hr class="mt-2 mb-2" style="border-top: 1px solid rgb(32 46 61) !important;">
        <router-link :to="list.path" tag="li" v-for="(list, index) in fourth_list" :key="'D' + index">
          <a><img :src="'/assets/img/' + list.icon" class="mr-3"/> <span class="menu-name">{{ list.name }}</span> </a>
        </router-link>
        <h6 class="side-title">REPORTS</h6>
        <hr class="mt-2 mb-2" style="border-top: 1px solid rgb(32 46 61) !important;">
        <router-link :to="list.path" tag="li" v-for="(list, index) in fifth_list" :key="'E' + index">
          <a><img :src="'/assets/img/' + list.icon" class="mr-3"/> <span class="menu-name">{{ list.name }}</span> </a>
        </router-link>
        <h6 class="side-title">SETTINGS</h6>
        <hr class="mt-2 mb-2" style="border-top: 1px solid rgb(32 46 61) !important;">
        <router-link :to="list.path" tag="li" v-for="(list, index) in sixth_list" :key="'F' + index">
          <a><img :src="'/assets/img/' + list.icon" class="mr-3"/> <span class="menu-name">{{ list.name }}</span> </a>
        </router-link>
        <h6 class="side-title">API Export</h6>
        <hr class="mt-2 mb-2" style="border-top: 1px solid rgb(32 46 61) !important;">
        <router-link :to="list.path" tag="li" v-for="(list, index) in seventh_list" :key="'G' + index">
          <a><img :src="'/assets/img/' + list.icon" class="mr-3"/> <span class="menu-name">{{ list.name }}</span> </a>
        </router-link>
      </ul> 
    </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      lists: [
        { name: 'Dashboard', icon: 'icon/icon-union.svg', path: '/' },
      ],
      second_list: [
        { name: 'Reservation', icon: 'icon/icon-reservation.svg', path: '/reservation' },
        { name: 'Reservation Attempt', icon: 'icon/icon-attempt.svg', path: '/reservation-attempt' },
        { name: 'Payments', icon: 'icon/icon-payment.svg', path: '/payments' },
        { name: 'External Charges', icon: 'icon/icon-external.svg', path: '/external-charges' },
        { name: 'Commissions', icon: 'icon/icon-commission.svg', path: '/Commissions' },
        { name: 'Commision Partner', icon: 'icon/icon-compartner.svg', path: '/commission-partner' },
      ],
      third_list: [
        { name: 'Car Track', icon: 'icon/icon-cartrack.svg', path: '/car-track' },
        { name: 'Vehicles', icon: 'icon/icon-vehicle.svg', path: '/vehicles' },
      ],
      fourth_list: [
      { name: 'Customer', icon: 'icon/icon-customer.svg', path: '/customer' },
      { name: 'Companies', icon: 'icon/icon-company.svg', path: '/companies' },
      ],
      fifth_list: [
      { name: 'Daily Report', icon: 'icon/icon-daily.svg', path: '/daily-report' },
      { name: 'Revenue Report', icon: 'icon/icon-revenue.svg', path: '/revenue-report' },
      { name: 'Cancellation Report', icon: 'icon/icon-cancel.svg', path: '/cancellation-report' },
      { name: 'Monthly Report', icon: 'icon/icon-monthly.svg', path: '/monthly-report' },
      { name: 'Commission Report', icon: 'icon/icon-comreport.svg', path: '/commission-report' },
      ],
      sixth_list: [
      { name: 'General Settings', icon: 'icon/icon-setting.svg', path: '/general-settings' },
      { name: 'User Management', icon: 'icon/user-manage.svg', path: '/user-management' },
      { name: 'User Roles', icon: 'icon/user-roles.svg', path: '/user-roles' },
      { name: 'Billing', icon: 'icon/icon-billing.svg', path: '/billing' },
      ],
      seventh_list: [
      { name: 'API', icon: 'icon/icon-api.svg', path: '/api' },
      { name: 'Export Reservation', icon: 'icon/icon-export.svg', path: '/export-reservations' }, 
      ]
    }
  },
  mounted() {
    // $("#sidebar").mousemove(function( event ) {
    //   var w = $(this).width(),
    //       pct = 360*(+event.pageX)/w,
    //       bg = "linear-gradient(" + pct + "deg, #111946, #081144)";
    //       $("#sidebar").css("background-image", bg);
    // });
  },
  methods: {

  },
}
</script>

<style>
</style>