let routes = [
  {
    path: '/', 
    component: render('Dashboard/Index'), 
    meta: { auth: true, title: 'Dashboard',
      breadcrumbs: [
        {'url':null, 'text': 'Admin'},
        {'url':'/', 'text': 'Dashboard'},
      ]
    },
  },
  {
    path: '/login', 
    component: render('Login'), 
    meta: { auth: false, authScreen: true },
  },
  {
    path: '/signup', 
    component: render('Signup'), 
    meta: { auth: false, authScreen: true },
  },
  {
    path: '/book',
    component: render('PublicReservation'),
    meta: { auth: false, authScreen: true, title: 'Public Reservation' },
    props: true,
  },

  /*   --- Missions ---   */
  {
    path: '/missions', 
    component: render('Mission/List/Missions'), 
    meta: { 
      auth: true, 
      title: 'Missions',
      breadcrumbs: [
        {'url':null, 'text': 'Admin'},
        {'url':'/missions', 'text': 'Missions'},
      ]
    },
  },
  {
    path: '/mission/details/:id',
    component: render('Mission/Detail/MissionDetails'),
    meta: { auth: true, title: 'Mission Details' },
    props: true,
  },
  {
    path: '/mission/edit/:id',
    component: render('Mission/Edit/MissionEdit'),
    meta: { auth: true, title: 'Edit Mission' },
    props: true,
  },

  /*    --- Company ---    */
  {
    path: '/companies',
    component: render('Company/Companies'),
    meta: { auth: true, title: 'Companies' },
  },
  {
    path: '/company/details/:id',
    component: render('Company/CompanyDetails'),
    meta: { auth: true, title: 'Company Details' },
    props: true,
  },
  {
    path: '/company/edit/:id',
    component: render('Company/Edit/EditForm'),
    meta: { auth: true, title: 'Edit Company' },
    props: true,
  },
  {
    // path: '/company/edit/bg/:id',
    path: '/company/:id/services/bodyguard',
    component: render('Company/Bodyguard/EditForm'),
    meta: { auth: true, title: 'Edit Company' },
    props: true,
  },
  {
    // path: '/company/edit/sg/:id',
    path: '/company/:id/services/security-guard',
    component: render('Company/SecurityGuard/EditForm'),
    meta: { auth: true, title: 'Edit Company' },
    props: true,
  },
  {
    // path: '/company/edit/ca/:id',
    path: '/company/:id/services/car-rental',
    component: render('Company/CarRental/EditForm'),
    meta: { auth: true, title: 'Edit Company' },
    props: true,
  },
  {
    // path: '/company/ca/:id/mission/:id',
    path: '/company/:id/services/car-rental/mission/:id',
    component: render('Company/CarRental/EditMission'),
    meta: { auth: true, title: 'Edit Mission Detail' },
    props: true,
  },
  {
    // path: '/company/edit/pi/:id',
    path: '/company/:id/services/private-investigator',
    component: render('Company/PrivateInvestigator/EditForm'),
    meta: { auth: true, title: 'Edit Company' },
    props: true,
  },
  {
    // path: '/company/edit/ed/:id',
    path: '/company/:id/services/education',
    component: render('Company/Education/List/Index'),
    meta: { auth: true, title: 'Edit Company' },
    props: true,
  },
  {
    // path: '/company/:id/ed/instructor',
    path: '/company/:id/services/education/instructor',
    component: render('Company/Education/Instructor/Add'),
    meta: { auth: true, title: 'Add Instructor Profile' },
    props: true,
  },
  {
    // path: '/company/:id/ed/instructor/:id',
    path: '/company/:id/services/education/instructor/:id',
    component: render('Company/Education/Instructor/Edit'),
    meta: { auth: true, title: 'Edit Instructor Profile' },
    props: true,
  },

  {
    // path: '/company/:id/ed/class',
    path: '/company/:id/services/education/class',
    component: render('Company/Education/Class/Add'),
    meta: { auth: true, title: 'Add Class' },
    props: true,
  },

  {
    // path: '/company/:id/ed/class/:id',
    path: '/company/:id/services/education/class/:id',
    component: render('Company/Education/Class/Edit'),
    meta: { auth: true, title: 'Edit Class' },
    props: true,
  },

  {
    // path: '/company/:id/ed/client-request/:id',
    path: '/company/:id/services/education/client-request/:id',
    component: render('Company/Education/ClientRequest/Edit'),
    meta: { auth: true, title: 'Edit Client Request' },
    props: true,
  },


  {
    path: '/company/edit-design/:id',
    component: render('Company/CompanyEdit1'),
    meta: { auth: true, title: 'Edit Company' },
    props: true,
  },
  {
    path: '/company-design/edit/:id',
    component: render('Company/CompanyEditDesign'),
    meta: { auth: true, title: 'Edit Company' },
    props: true,
  },
  {
    path: '/add/company/',
    component: render('Company/AddCompany'),
    meta: { auth: true, title: 'Create New Company' },
  },
  {
    path: '/new/company/',
    component: render('AddCompany'),
    meta: { auth: true, title: 'Create New Company' },
  },
  {
    path: '/company/:company_id/add/agent/',
    component: render('Company/AddAgent'),
    meta: { auth: true, title: 'Create New Agent' },
  },
  {
    path: '/company/:company_id/edit/agent/:id',
    component: render('Company/EditAgent'),
    meta: { auth: true, title: 'Edit Agent' },
  },
  {
    path: '/company/edit/vip/:id',
    component: render('Company/VIP/EditForm'),
    meta: { auth: true, title: 'Edit Agent' },
  },

  /*   --- Freelancer ---   */
  {
    path: '/freelancer', 
    component: render('Freelance'), 
    meta: { auth: true, title: 'Freelancer' } 
  },
  {
    path: '/edit/freelancer/:id',
    component: render('Freelance/FreelanceEdit'),
    meta: { auth: true, title: 'Edit Freelancer' },
    props: true,
  },
  {
    path: '/add/freelancer',
    component: render('Freelance/AddFreelancer'),
    meta: { auth: true, title: 'Adding Freelancer' }
  },

  /*   --- Reservation ---   */
  {
    path: '/reservation', 
    component: render('Reservation'), 
    meta: { auth: true, title: 'Reservation' } 
  },
  {
    path: '/availability/reservation/',
    component: render('Reservation/Availability'),
    meta: { auth: true, title: 'Check Availability' },
    props: true,
  },
  {
    path: '/new/reservation/',
    component: render('Reservation/AddReservation'),
    meta: { auth: true, title: 'New Reservation' },
    props: true,
  },
  {
    path: '/reservation-attempt/',
    component: render('ReservationAttempt'),
    meta: { auth: true, title: 'Reservation Attempts' },
    props: true,
  },
  

  /*   --- Payment ---  */
  {
    path: '/payments/',
    component: render('Payments'),
    meta: { auth: true, title: 'Payments' },
    props: true,
  },

  /*   --- API ---  */
  {
    path: '/api/',
    component: render('Api'),
    meta: { auth: true, title: 'Api' },
    props: true,
  },
  {
    path: '/export-reservations/',
    component: render('Export'),
    meta: { auth: true, title: 'Export Reservations' },
    props: true,
  },

  /*   --- Billing ---  */
  {
    path: '/billing/',
    component: render('Billing'),
    meta: { auth: true, title: 'Billing' },
    props: true,
  },

  /*   --- External Charges ---  */
  {
    path: '/external-charges/',
    component: render('ExternalCharges'),
    meta: { auth: true, title: 'External Charges' },
    props: true,
  },

  /*   --- Commission ---  */
  {
    path: '/commissions/',
    component: render('Commission'),
    meta: { auth: true, title: 'Commissions' },
    props: true,
  },

  /*   --- Commission Partner ---  */
  {
    path: '/commission-partner/',
    component: render('ComPartner'),
    meta: { auth: true, title: 'Commission Partner' },
    props: true,
  },
  {
    path: '/new/commission-partner/',
    component: render('CommissionPartner/AddComPartner'),
    meta: { auth: true, title: 'Add Commission Partner' },
    props: true,
  },

  /*   --- Vehicle ---  */
  {
    path: '/vehicles/',
    component: render('Vehicle'),
    meta: { auth: true, title: 'Vehicles' },
    props: true,
  },
  {
    path: '/new/vehicles/',
    component: render('Vehicle/AddVehicle'),
    meta: { auth: true, title: 'Add Vehicles' },
    props: true,
  },

  /*   --- User Management ---  */
  {
    path: '/user-management/',
    component: render('UserManagement'),
    meta: { auth: true, title: 'User Management' },
    props: true,
  },

  /*   --- User Roles ---  */
  {
    path: '/user-roles/',
    component: render('UserRoles'),
    meta: { auth: true, title: 'User Roles' },
    props: true,
  },

  /*   --- Car Track ---  */
  {
    path: '/car-track/',
    component: render('CarTrack'),
    meta: { auth: true, title: 'Car Track' },
    props: true,
  },

  /*   --- Reports ---  */
  {
    path: '/daily-report/',
    component: render('Reports/DailyReport'),
    meta: { auth: true, title: 'Daily Report' },
    props: true,
  },
  {
    path: '/revenue-report/',
    component: render('Reports/RevenueReport'),
    meta: { auth: true, title: 'Revenue Report' },
    props: true,
  },
  {
    path: '/cancellation-report/',
    component: render('Reports/Cancellation'),
    meta: { auth: true, title: 'Cancellation Report' },
    props: true,
  },
  {
    path: '/monthly-report/',
    component: render('Reports/MonthlyReport'),
    meta: { auth: true, title: 'Monthly Report' },
    props: true,
  },
  {
    path: '/commission-report/',
    component: render('Reports/Commission'),
    meta: { auth: true, title: 'Commission Report' },
    props: true,
  },
  
  /*   --- Admin User ---  */
  {
    path: '/staff',
    component: render('Staff/StaffList'),
    meta: { 
            auth: true, 
            title: 'Admin Users', 
            breadcrumbs: [
              {'url':null, 'text': 'Admin'},
              {'url':'/staff', 'text': 'Admin Users'},
            ]
          },
  },
  {
    path: '/staff/details/:id',
    component: render('Staff/StaffDetail'),
    meta: { 
      auth: true, 
      title: 'Admin Detail', 
      breadcrumbs: [
        {'url':null, 'text': 'Admin'},
        {'url':'/staff', 'text': 'Admin Detail'},
      ]
    },
    props: true,
  },
  {
    path: '/staff/edit/:id',
    component: render('Staff/StaffEdit'),
    meta: { 
      auth: true, 
      title: 'Admin Profile', 
      breadcrumbs: [
        {'url':null, 'text': 'Admin'},
        {'url':'/staff', 'text': 'Admin Users'},
        {'url':null, 'text': 'Edit Admin'},
      ]
    },
    props: true,
  },
  {
    path: '/add/staff',
    component: render('Staff/NewStaff'),
    meta: { auth: true, title: 'Create Admin User' },
    props: true,
  },

  /*   --- Finance ---   */
  {
    path: '/finance',
    component: render('Finance/Finance'),
    meta: { auth: true, title: 'Finance' },
  },


  /*   --- Quotation ---   */
  {
    path: '/quotation',
    component: render('Quotations/List/List'),
    meta: { 
      auth: true, 
      title: 'Quotation',
      breadcrumbs: [
        {'url':null, 'text': 'Admin'},
        {'url':null, 'text': 'Quotation'},
      ]
    },
  },
  {
    path: '/quotation/edit/:id',
    component: render('Quotations/Edit/Edit'),
    meta: { 
      auth: true, 
      title: 'Edit Quotation',
      breadcrumbs: [
        {'url':null, 'text': 'Admin'},
        {'url':null, 'text': 'Edit Quotation'},
      ]
    },
    props: true,
  },

  /*   --- Customer ---   */
  { 
    path: '/customer',
    component: render('Customer'),
    meta: { 
      auth: true, 
      title: 'Customer',
      breadcrumbs: [
        {'url':null, 'text': 'Admin'},
        {'url':null, 'text': 'Customer'},
      ]
    },
  },
  {
    path: '/new/customer',
    component: render('Customer/AddCustomer'),
    meta: { auth: true, title: 'Add Customer' },
    props: true,
  },
  {
    path: '/customer/details/:id',
    component: render('Customer/CustomerDetails'),
    meta: { auth: true, title: 'Customer Profile' },
    props: true,
  },
  {
    path: '/customer/:id',
    component: render('Customer/CustomerEdit'),
    meta: { auth: true, title: 'Customer Profile' },
    props: true,
  },
  { 
    path: '/vip-services',
    component: render('VipServices'),
    meta: { auth: true, title: 'VIP Services' },
  },

  /*   --- Approval ---   */
  { 
    path: '/approval-account',
    component: render('Approval/ApprovalAccount'),
    meta: { auth: true, title: 'Approval Account' },
  },
  { 
    path: '/approval-account/rejected',
    component: render('Approval/ApprovalAccountReject'),
    meta: { auth: true, title: 'Approval Account' },
  },
  { 
    path: '/approval-text',
    component: render('Approval/ApprovalText'),
    meta: { auth: true, title: 'Approval Text' },
  },
  {
    path: '/approval-text/rejected',
    component: render('Approval/ApprovalTextReject'),
    meta: { auth: true, title: 'Approval Text' },
  },
  {
    path: '/approval-comment',
    component: render('Approval/ApprovalComment'),
    meta: { auth: true, title: 'Approval Comment' },
  },
  { 
    path: '/approval-comment/rejected',
    component: render('Approval/ApprovalCommentReject'),
    meta: { auth: true, title: 'Approval Comment' },
  },
  {
    path: '/approval-image/profile',
    component: render('Approval/ApprovalImageProfile'),
    meta: { auth: true, title: 'Approval Image' },
  },
  { 
    path: '/approval-image/profile/rejected',
    component: render('Approval/ApprovalImageProfileReject'),
    meta: { auth: true, title: 'Approval Image' },
  },
  { 
    path: '/approval-image/gallery',
    component: render('Approval/ApprovalImageGallery'),
    meta: { auth: true, title: 'Approval Image' },
  },
  { 
    path: '/approval-image/gallery/rejected',
    component: render('Approval/ApprovalImageGalleryReject'),
    meta: { auth: true, title: 'Approval Image' },
  },
  { 
    path: '/approval-image/video',
    component: render('Approval/ApprovalVideo'),
    meta: { auth: true, title: 'Approval Image' },
  },
  { 
    path: '/approval-image/video/rejected',
    component: render('Approval/ApprovalVideoReject'),
    meta: { auth: true, title: 'Approval Image' },
  },
  
  /*   --- Settings ---   */
  { 
    path: '/general-settings',
    component: render('Settings/Setting'), 
    meta: { 
      auth: true,
      title: 'General Settings',
      breadcrumbs: [
        {'url':null, 'text': 'Admin'},
        {'url':'/settings', 'text': 'Settings'},
      ]
    },
  },
  { 
    path: '/contract-settings',
    component: render('ContractSetting'), 
    meta: { auth: true, title: 'Contract Settings' },
  },
  {
    path: '/contract-settings/edit/:id',
    component: render('ContractSettingEdit'),
    meta: { auth: true, title: 'Edit Contract' },
    props: true,
  },
  {
    path: '/add/contract',
    component: render('ContractSettingAdd'),
    meta: { auth: true, title: 'Create Contract' },
    props: true,
  },

  /*   --- Error Page ---   */
  { 
    path: '/404',
    component: render('ErrorPage'),
    meta: { auth: true },
  },
  { 
    path: '/*',
    redirect: '/404',
    meta: { authScreen: true },
  },
]

function render(template) {
  return require(`@/pages/${template}.vue`).default
}

export default routes
