<template>
    <div>
          <!----- Header Title ----->
      <div class="row">
        <div class="col-md-6">
          <div>
            <h3 class="page-title mb-1">
                  {{ this.$route.meta.title }}
            </h3>
          </div>
          <div class="breadcrumb-container">
            <ul class="uk-breadcrumb">
              <li><span>Admin</span></li>
              <li><a href="">{{ this.$route.meta.title }}</a></li>
            </ul>
          </div>
        </div>
        <div class="col-md-6 relative"></div>
      </div>
      <div class="row mt-2">
        <div class="col-md-8">
        </div>
        <div class="col-md-4 relative">
          <div class="uk-position-bottom-right pr-3">
            <div class="mb-3 text-right">
              <router-link class="mr-2" :to="'/'">
                <a uk-toggle="target: #adduser" class="outline-btn"><i class="fas fa-plus mr-2"></i>Add User</a>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table data-table table-round table-hover">
          <thead>
            <tr>
              <th>
                    ID
                    <Sort column="id" />
                  </th>
                  <th>
                    Full Name
                    <Sort column="name_en" />
                  </th>
                  <th>
                    Username
                  </th>
                  <th>
                    Email Address
                  </th>
                  <th>
                    Phone Number
                  </th>
                  <th>
                    Roles
                  </th>
                  <th>
                    Enabled
                  </th>
                  <th>
                    Last Activity
                  </th>
                  <th>
                    Created At
                  </th>
                  
                  <th class="blankhead"></th>
            </tr>
          </thead>
          <tbody>
                <tr>
                  <td>001</td>
                  <td>Hanah Salazar</td>
                  <td>hannahendell</td>
                  <td>hannahendell@gmail.com</td>
                  <td>09123456789</td>
                  <td>Administrator</td>
                  <td><span class="badge badge-sm badge-success">Yes</span></td>
                  <td>30-01-2024 23:23</td>
                  <td>30-01-2024 23:23</td>
                  <td class="text-right">
                    <a href="#" class="del-btn"><i class="fal fa-trash-alt"></i></a>
                  </td>
                </tr>
                <tr>
                  <td>001</td>
                  <td>Hanah Salazar</td>
                  <td>hannahendell</td>
                  <td>hannahendell@gmail.com</td>
                  <td>09123456789</td>
                  <td>Administrator</td>
                  <td><span class="badge badge-sm badge-success">Yes</span></td>
                  <td>30-01-2024 23:23</td>
                  <td>30-01-2024 23:23</td>
                  <td class="text-right">
                    <a href="#" class="del-btn"><i class="fal fa-trash-alt"></i></a>
                  </td>
                </tr>
                <tr>
                  <td>001</td>
                  <td>Hanah Salazar</td>
                  <td>hannahendell</td>
                  <td>hannahendell@gmail.com</td>
                  <td>09123456789</td>
                  <td>Administrator</td>
                  <td><span class="badge badge-sm badge-success">Yes</span></td>
                  <td>30-01-2024 23:23</td>
                  <td>30-01-2024 23:23</td>
                  <td class="text-right">
                    <a href="#" class="del-btn"><i class="fal fa-trash-alt"></i></a>
                  </td>
                </tr>
                <tr>
                  <td>001</td>
                  <td>Hanah Salazar</td>
                  <td>hannahendell</td>
                  <td>hannahendell@gmail.com</td>
                  <td>09123456789</td>
                  <td>Administrator</td>
                  <td><span class="badge badge-sm badge-success">Yes</span></td>
                  <td>30-01-2024 23:23</td>
                  <td>30-01-2024 23:23</td>
                  <td class="text-right">
                    <a href="#" class="del-btn"><i class="fal fa-trash-alt"></i></a>
                  </td>
                </tr>
              </tbody>
          <tfoot>
          </tfoot>
        </table>
      </div>
      <div class="card-footer pb-0 pt-3" v-if="numberOfPage > 1">
            <jw-pagination
              :items="pages"
              @changePage="onChangePage"
              :pageSize="limit"
              :styles="customStyles"
              :labels="customLabels"
            ></jw-pagination>
          </div>

          <!-- Add Modal -->
        <div id="adduser" uk-modal>
            <div class="uk-modal-dialog uk-modal-body p-2 text-center">
                <button class="uk-modal-close-outside" type="button" uk-close></button>
                <div class="row align-items-center mb-4">
                    <div class="col-lg-12">
                    <div class="grey-box">
                        <div class="view-ui mt-4 mb-2">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <input type="text" class="form-control" value="" autocomplete="off" required>
                                        <label>First Name</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <input type="text" class="form-control" value="" autocomplete="off" required>
                                        <label>Last Name</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <input type="text" class="form-control" value="" autocomplete="off" required>
                                        <label>Username</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <input type="email" class="form-control" value="" autocomplete="off" required>
                                        <label>Email Address</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <input type="text" class="form-control" value="" autocomplete="off" required>
                                        <label>Password</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <input type="tel" class="form-control" value="" autocomplete="off" required>
                                        <label>Phone Number</label>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <select class="form-control" required>
                                            <option></option>
                                            <option>Administrator</option>
                                            <option>User</option>
                                            <option>Manager</option>
                                            <option>Reservation</option>
                                        </select>
                                        <label>Roles</label>
                                    </div>
                                </div>
                                <div class="clearfix"></div>
                                
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                <a href="#" class="uk-button uk-button-primary mr-3" type="button">Save</a>
            </div>
        </div>
      <!-- End modal -->

    </div>
    
  </template>
  
  
  
  <script>

  export default {
    
  }
  </script>
  
  <style scoped>
  .card-footer {
    text-align: right;
    position: relative;
    background: #fff;
  }
  .pagination {
    top: 20px;
    position: absolute;
    right: 0px;
    align-content: flex-end;
    text-align: end;
  }
  </style>